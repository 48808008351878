import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiFedora,
  SiSlack,
  SiZsh,
  SiLinux,
  SiNotion,
  SiHashnode,
  SiGoogle,
  SiVim,
  SiGnometerminal
} from "react-icons/si";



function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux  />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFedora />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSlack />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiZsh />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNotion />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHashnode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGoogle />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVim />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGnometerminal />
      </Col>
    </Row>
  );
}

export default Toolstack;

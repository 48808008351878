import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import distroless from "../../Assets/Projects/distroless.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import cicd from "../../Assets/Projects/cicd.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Projects I've worked on recently
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Cloud Resume Challenge "
              description="Project involves creating and hosting a portfolio website on Google Cloud Platform, utilizing cloud infrastructure, serverless functions, load balancing, CDN, Frontend Development, HTTPS, DNS, API's, Testing, IaC, and CI/CD pipelines. "
              ghLink="https://github.com/ChetanThapliyal/Cloud-resume-challenge-FE-GCP-Serverless"
              demoLink="https://"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Aure Devops CICD Pipeline"
              description="Robust, automated Continuous Integration and Continuous Deployment (CI/CD) pipeline using Azure DevOps. This pipeline will enable seamless integration and deployment of applications, ensuring high quality and rapid delivery through automated testing, building, and deployment processes."
              ghLink="https://github.com/ChetanThapliyal/AzureDevops-CICD-Project"
              // demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="3-tier application deployment"
              description="Deploying a YelpCamp application (a full-stack website for campground reviews) across various environments (local,docker,GKE) using Cloud DevOps practices."
              ghLink="https://github.com/ChetanThapliyal/yelpcamp-devops-gcp-docker-gke"
              // demoLink=""              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={distroless}
              isBlog={false}
              title="Distroless Docker Builds (Hugo)"
              description="Docker Image optimization using multi-stage Docker builds for efficient and lean Hugo deployments. Docker distroless images are minimal base images that contain only the application and its runtime dependencies, significantly reducing the attack surface and improving security and performance."
              ghLink="https://github.com/ChetanThapliyal/hugo-portfolio-multistage-docker"
              demoLink="https://blog.chetan-thapliyal.cloud/optimizing-docker-images-with-multistage-builds-a-hugo-portfolio-example"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cicd}
              isBlog={false}
              title="Secure-cloudNative-CI-CD-pipeline"
              description="Security-centric CI/CD pipeline that integrates cutting-edge tools and practices to ensure robust code quality, vulnerability scanning, artifact publishing, secure Kubernetes deployment, and continuous monitoring."
              ghLink="https://github.com/ChetanThapliyal/Secure-cloudNative-CI-CD-pipeline"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="AlertOps Monitor"
              description="Establish a comprehensive monitoring and alerting system for a web service using Prometheus, Alertmanager, and other supporting tools. The primary objective is to ensure that the web service's performance and availability are continuously monitored, and any anomalies or issues are promptly detected and communicated to the relevant stakeholders. "
              ghLink="https://github.com/ChetanThapliyal/AlertOps-Monitor"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
